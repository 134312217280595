<template>
  <v-container>
    <base-card light>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="5"
          sm="12"
        >
          <v-img
            :src="require('@/assets/heads/head1green.png')"
            class="darken-4"
            height="250"
            width="100%"
            contain
          />
        </v-col>
        <v-col
          md="7"
          sm="12"
        >
          <h1 class="display-3 font-weight-light mb-5">
            {{ content.legendHeading }}
          </h1>

          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.legend"
          ></div>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'LegendCard',
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
  }
</script>

<style scoped>
  .p-hr {
    border: 3px solid #3F5465;
    border-radius: 5px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .p-title {
    margin-top: 50px;
    margin-bottom: 25px;
  }
</style>
